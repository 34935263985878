<template>
  <div class="acontainer">
    <div class="ad">
      <div class="atitle">
        <a href="/free-credits" title="Discounts & Free Credits">
          Discounts & Free Credits</a>
      </div>
      <div class="alogo"><a href="/free-credits" title="Discounts & Free Credits">
        <img src="../assets/ads/mail.png" width="90" height="90" alt="Newsletter"></a>
      </div>
      <div class="asubtitle">
        <a class="asubtitle" href="/free-credits" title="Second Number">
          Signup and receive discounts and free credits regularly via email!
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CAdNewsletter'
}
</script>

<style lang="scss">
.acontainer {
  margin-bottom: 15px;
  margin-top: 15px;
}

.ad {
  border: 1px solid #15b76c;
  max-width: 220px;
  margin: auto;
}

.alogo {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.acenter {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.atitle {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.atitle a {
  color: #15b76c;
}

.unlimited {
  color: #15b76c;
}

.asubtitle {
  text-align: center;
  margin: 0.5rem 0.2rem;
}

.asubtitle a {
  font-size: 0.9em;
  color: #000;
}

</style>
