<template>
  <CMessage
    title="Verify email"
    message="In order to use our service you need to verify your email."
    :link-to="{name: 'verify'}"
    link-label="Verify email"
  />
</template>

<script>
import CMessage from './CMessage'

export default {
  name: 'CVerifyEmail',
  components: { CMessage }
}
</script>
