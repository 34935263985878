<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <o-form-checkbox name="accept_tos" required>
    I accept &nbsp;
    <o-link :to="{name: 'terms'}" target="_blank">Terms of use</o-link>
<!--    <template v-slot:tooltip>-->
<!--      For legal reasons we must ask you to accept our terms of use, before we are allowed to-->
<!--      provide you with our service-->
<!--    </template>-->
  </o-form-checkbox>
</template>

<script>
import OFormCheckbox from './OFormCheckbox'
import OLink from '../OLink'
export default {
  name: 'OFormTOS',
  components: { OLink, OFormCheckbox }
}
</script>
