<template>
  <div
    class="btn-container"
    id="appleid-signin"
    data-mode="center-align"
    data-type="sign-in"
    data-color="white"
    data-border="true"
    data-border-radius="5"
    data-width="100%"
    data-height="38"
    data-logo-size="large"
  ></div>
</template>

<script>
let added = false

export default {
  name: 'CAppleLoginButton',
  methods: {
    init () {
      document.addEventListener('AppleIDSignInOnSuccess', data => {
        this.$emit('login', data.detail.authorization.code)
      })

      global.AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLE_SIGN_IN_CLIENT_ID,
        scope: 'name email',
        redirectURI: process.env.VUE_APP_APPLE_SIGN_IN_REDIRECT_URL,
        usePopup: true
      })
    }
  },
  mounted () {
    if (!added) {
      const script = document.createElement('script')
      script.addEventListener('load', () => {
        this.init()
      })
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
      script.async = true
      script.defer = true
      document.head.appendChild(script)
      added = true
    } else {
      this.init()
    }
  }
}
</script>

<style land="scss" scoped>
.btn-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  cursor: pointer;
}
</style>
