<template>
  <h2 class="o-title"><slot></slot></h2>
</template>

<script>
export default {
  name: 'OTitle'
}
</script>

<style lang="scss">
  .o-title {
    display: block;
    @include typi('title');
    text-align: center;
    color: $primary;
    padding: 0;
    margin: 0 0 $vertical-margin;
    text-transform: uppercase;
    font-weight: normal;
    position: relative;

    &:before {
      border-top: 1px solid $primary;
    }

    &:after {
      border-bottom: 1px solid $primary;
    }

    &:before, &:after {
      content: "";
      display: block;
      height: vr(0.5);
    }

    @include two-column() {
      display: none;
    }
  }
</style>
