<template>
  <div>
    <h2 class="faq">Spoof Call - how it works?</h2>
    You can send a free spoof call on this page. Enter the target number you want to call, the spoof number to be
    displayed( fake caller id ), and your real number. Our caller id faker will call your number and bridge the spoof
    call with the target number. Your real number is never revealed. <br/>
    <br/>

    <h2 class="faq">Can spoof calls be traced?</h2>
    Tracing a spoof call is a heavy and timely process. It gets more problematic since the outbound caller id used for
    the call is usually set from the user calling and not the actual owner. The way to trace a fake caller id would be
    through law enforcement - it happens very rarely, for serious crimes, but our advice is to stay safe and do not
    break the law! <br/>
    <br/>

    <h2 class="faq">Can I use a fake caller id?</h2>
    You can set any number you want to protect your privacy. Some people call it a fake caller id - it is actually a
    valid US number. <br/>
    <br/>

    <h2 class="faq">Caller Id Faker can spoof messages!?</h2>
    You can try, but mobile operators, especially in the USA are strict with text messages and for most of your texts,
    the spoofed number will be replaced with a random number. That's why for texting we recommend anonymous texting
    service where you control the random numbers so you can receive replies. We are already offering this service.
  </div>
</template>

<script>
export default {
  name: 'CFAQDesktop'
}
</script>

<style lang="scss">
.faq {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.17em;
}
</style>
