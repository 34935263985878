<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm @submit="submit" :class="{submitting}">
    <!--

       IMPORTANT: field names MUST contains "search" keyword to disable safari autocomplete,
       which populates all fields with the same value

    -->
    <PhoneNumberInput
      id="to-number"
      v-model="form.to"
      :disabled="submitting"
      name="target-search"
      placeholder="10 digit number to call"
      required
      autocomplete="section-target tel"
      @invalid="toValid=false"
      @valid="toValid=true"
    >
      Number to call
      <template v-slot:tooltip>
        Target phone number to dial.
      </template>
    </PhoneNumberInput>
    <PhoneNumberInput
      id="from-number"
      v-model="form.from"
      :disabled="submitting"
      name="spoof-search"
      placeholder="10 digit display number"
      required
      autocomplete="section-spoof tel"
      @invalid="fromValid=false"
      @valid="fromValid=true"
    >
      Spoof number
      <template v-slot:tooltip>
        Number to display on the target screen.<br/>This can be any valid phone number.
      </template>
    </PhoneNumberInput>
    <PhoneNumberInput
      id="real-number"
      v-model="form.real"
      :disabled="submitting"
      name="own-search"
      placeholder="10 digit number of yours"
      required
      autocomplete="section-own tel"
      @invalid="realValid=false"
      @valid="realValid=true"
    >
      Your phone number
      <template v-slot:tooltip>
        Your real phone number.<br/>We will call you first, you pick up, we bridge the call.
      </template>
    </PhoneNumberInput>
    <div @click="showErrorVoice()">
      <OFormSingleChoice
        style="margin-bottom: 2rem"
        :value="'off'"
        type="buttons"
        :options="['off', 'man', 'woman']"
        :disabled="false"
      >
        Voice changer
      </OFormSingleChoice>
    </div>
    <OFormError :message="errorVoice"></OFormError>
    <div @click="showErrorRecording()">
      <OFormSingleChoice
        style="margin-bottom: 2rem;"
        :value="'off'"
        type="buttons"
        :options="['off', 'on']"
        :disabled="false"
      >
        Call recording
      </OFormSingleChoice>
    </div>
    <OFormError :message="errorRecording"></OFormError>
    <OFormTOS></OFormTOS>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting" style="width: 66%">
        Call
      </OFormSubmitButton>
    </FormButtons>
    <div style="margin-bottom: 10px">
      <o-link :to="{name: 'feedback'}">
        <span :class="$style['a-text']">For support and feedback</span> CLICK HERE</o-link>
    </div>
    <OFormHelp>
      We are calling your number, you pick up, then we bridge the call with your fake caller id.
      <em>Please, switch off any call blocker or spam filter so you can receive calls from unknown numbers.</em>
      Our free caller id faker is limited to 40 seconds. You can remove all limits and add <em>voice changer</em>
      and <em>call recording</em> if you Join.
    </OFormHelp>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import PhoneNumberInput from '../objects/forms/OFormPhoneNumberInput'
import OForm from '../objects/forms/OForm'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormTOS from '../objects/forms/OFormTOS'
import OFormHelp from '../objects/forms/OFormHelp'
import OFormSingleChoice from '../objects/forms/OFormSingleChoice'
import OLink from '@/objects/OLink'

export default {
  name: 'CFormFreeCall',
  components: {
    OFormHelp,
    OFormError,
    FormButtons,
    OFormSubmitButton,
    OForm,
    PhoneNumberInput,
    OFormTOS,
    OFormSingleChoice,
    OLink
  },
  mixins: [apiForm],
  data: () => ({
    form: {
      from: null,
      to: null,
      real: null,
      token: null
    },
    errorVoice: null,
    errorRecording: null
  }),
  methods: {
    async submit () {
      if (!this.form.to) {
        let valueNumber = document.getElementById('to-number').value
        this.form.to = '+1' + valueNumber.replace(/\D/g, '')
      }
      if (!this.form.from) {
        let valueNumber = document.getElementById('from-number').value
        this.form.from = '+1' + valueNumber.replace(/\D/g, '')
      }
      if (!this.form.real) {
        let valueNumber = document.getElementById('real-number').value
        this.form.real = '+1' + valueNumber.replace(/\D/g, '')
      }

      await this.$recaptchaLoaded()
      this.form.token = await this.$recaptcha('freespoofcall')

      await this.$callApi(api => api.freeCall(this.form))
    },
    showErrorVoice () {
      this.errorVoice = 'You need to login in order to use VOICE CHANGER.'
    },
    showErrorRecording () {
      this.errorRecording = 'You need to login in order to use CALL RECORDING.'
    }
  }
}
</script>

<style lang="scss" scoped>
.hint {
  color: #666666;
}

.error {
  color: #ff0000;
}

.hint, .error {
  font-size: 0.75em;
  line-height: normal;
  margin-bottom: 1em;
}

.bitcoin-icon {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
</style>
<style lang="scss" module>
.a-text {
  color: #000000 !important;
  text-transform: none !important;
}
</style>
