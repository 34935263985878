<template>
  <OCColumn class="dashboard-container bridge-call">
    <OTitle>Bridge Call</OTitle>
    <CShareThisInline/>
    <CVerifyEmail v-if="!isActive"></CVerifyEmail>
    <CFormVoiceChanger v-if="isActive"></CFormVoiceChanger>
    <CAdSecondNumber/>
  </OCColumn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CFormVoiceChanger from '../forms/CFormVoiceChanger'
import CVerifyEmail from './CVerifyEmail'
import OCColumn from '../objects/containers/OCColumn'
import CAdSecondNumber from './CAdSecondNumber'
import OTitle from '@/objects/OTitle.vue'
import CShareThisInline from '@/components/CShareThisInline.vue'

export default {
  name: 'Dashboard',
  components: {
    CShareThisInline,
    OTitle,
    CAdSecondNumber,
    CFormVoiceChanger,
    OCColumn,
    CVerifyEmail
  },
  computed: {
    ...mapGetters({
      isActive: 'isActive'
    })
  },
  methods: {
    ...mapActions(['fetchInfo'])
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    flex-grow: 1;
  }
}

.c_follow {
  margin-bottom: 1rem;
}
</style>
