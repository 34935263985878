<template>
  <FormGroup
    class="o-form__checkbox"
    :class="{
    'is-valid': showValidity && valid,
    'is-invalid': showValidity && !valid
  }">
    <!--suppress HtmlFormInputWithoutLabel -->
    <input
      ref="input"
      v-bind="inputAttributes"
      v-on="inputListeners"
      class="o-form__checkbox__input"
      type="checkbox"
      :checked="value"
      :id="htmlId"
      :name="name"
    >
    <label class="o-form__checkbox__label" :for="htmlId">
      <slot></slot>
    </label>
  </FormGroup>
</template>

<script>
import htmlId from '../../mixins/htmlId'
import FormGroup from './FormGroup'

export default {
  name: 'OFormCheckbox',
  mixins: [htmlId],
  components: { FormGroup },
  props: {
    name: String,
    value: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      dirty: false,
      valid: false,
      showValidity: false
    }
  },
  computed: {
    inputAttributes () {
      return this.$attrs
    },
    inputListeners () {
      return {
        ...this.$listeners,
        change: this.onChange,
        input: this.onInput
      }
    }
  },
  methods: {
    updateValidity () {
      this.valid = this.$refs.input && this.$refs.input.checkValidity()
    },
    onInput ({ target: { value } }) {
      this.$emit('input', value)
      this.dirty = true
      this.showValidity = true
      this.updateValidity()
    },
    onChange ({ target: { value } }) {
      this.$emit('change', value)
      this.updateValidity()
    },
    setCustomValidity (msg) {
      this.$refs.input.setCustomValidity(msg)
      this.updateValidity()
    }
  }
}
</script>

<style lang="scss">
  .o-form__checkbox {
    position: relative;
    display: block;
    min-height: 1em;
    padding-left: 1.5em;
  }

  .o-form__checkbox__input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ .custom-control-label::before {
      color: $bg-color;
      border-color: #33cc33;
      background-color: #11ee11;
    }

    &:focus ~ .custom-control-label::before {
      /*box-shadow: 0 0 0 1px transparentize(darken(#33cc33, 25%), 0.75);*/
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
      /*box-shadow: 0 0 0 1px transparentize(darken($bg-color, 50%), 0.75);*/
    }

    &:not(:disabled):active ~ .custom-control-label::before {

    }

    &:disabled {
      ~ .custom-control-label {
        color: lighten($text-color, 33%);

        &::before {
          border-color: darken($bg-color, 33%);
          background-color: darken($bg-color, 25%);
        }
      }
    }
  }

  .o-form__checkbox__label {
    position: relative;
    vertical-align: top;
    padding: 1em 0;
    margin: -1em 0;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 1em;
      height: 1em;
    }

    &::before {
      top: 1em;
      left: -1.5em;
      pointer-events: none;
      background-color: $bg-color;
      border: darken($bg-color, 25%) solid 1px;
    }

    &::after {
      top: 1em;
      left: (-1.6em - 0.5em);
      background: no-repeat 50% / 1em;
      padding: vr(0.5);
      margin-top: (- vr(0.45));
    }
  }

  .o-form__checkbox {
    .o-form__checkbox__label::before {
      border-radius: 2px;
    }

    .o-form__checkbox__input:checked ~ .o-form__checkbox__label {
      &::after {
        background-image: url(svg-encode(icon-checkmark($primary)));
      }
    }

    .o-form__checkbox__input:indeterminate ~ .o-form__checkbox__label {
      &::before {
      }

      &::after {
      }
    }
  }
</style>
