<template>
  <div class="btn-container" ref="gbtn"></div>
</template>

<script>
let added = false

export default {
  name: 'CGoogleLoginButton',
  methods: {
    init () {
      global.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID,
        'data-auto_prompt': 'false',
        'data-context': 'signin',
        'data-ux_mode': 'redirect',
        callback: res => this.handleCredentialResponse(res)
      })
      global.google.accounts.id.renderButton(this.$refs.gbtn, {
        logo_alignment: 'center',
        type: 'standard',
        theme: 'outline',
        size: 'large',
        shape: 'rectangular',
        width: this.$refs.gbtn.clientWidth
      })
    },
    handleCredentialResponse (response) {
      this.$emit('login', response.credential)
    }
  },
  mounted () {
    if (!added) {
      const script = document.createElement('script')
      script.addEventListener('load', () => {
        this.init()
      })
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.defer = true
      document.head.appendChild(script)
      added = true
    } else {
      this.init()
    }
  }
}
</script>

<style land="scss" scoped>
.btn-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}
</style>
