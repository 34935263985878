<template>
  <div class="o-jl-links">
    <o-link class="o-jl-links__link" :to="{name: 'register'}">Join</o-link>
    <o-link class="o-jl-links__link" :to="{name: 'login'}">Login</o-link>
  </div>
</template>

<script>
import OLink from './OLink'

export default {
  name: 'OJLLinks',
  components: { OLink }
}
</script>

<style lang="scss">
  .o-jl-links {
    display: flex;
    font-size: 1.5rem;
    padding: 0em 0.5em 1em;
  }

  .o-jl-links__link {
    flex: 1 1 auto;
    text-align: center;
  }
</style>
