exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CFormFreeCall_a-text_19Z05{color:#000!important;text-transform:none!important}", ""]);

// exports
exports.locals = {
	"a-text": "CFormFreeCall_a-text_19Z05"
};