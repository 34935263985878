<template>
  <div class="btn-group">
    <CGoogleLoginButton @login="onLogin('google', $event)"></CGoogleLoginButton>
    <CAppleLoginButton @login="onLogin('apple', $event)"></CAppleLoginButton>
  </div>
</template>

<script>
import CGoogleLoginButton from '../components/CGoogleLoginButton'
import CAppleLoginButton from '../components/CAppleLoginButton'

export default {
  name: 'CSocialLogin',
  components: { CGoogleLoginButton, CAppleLoginButton },
  methods: {
    onLogin (social, credential) {
      this.$emit('login', { social, credential })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-group {
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  margin-bottom: 40px;

  @include one-column {
    & > * + * {
      margin-top: $vertical-margin;
    }
  }

  @include two-column {
    flex-flow: row nowrap;

    & > * {
      flex: 1;
      min-width: 0;
    }

    & > * + * {
      margin-left: $horizontal-margin;
    }
  }
}
</style>
