<template>
  <div class="bridge-call">
    <OTitle v-if="$isMobile()">Bridge Call</OTitle>
    <OTitle v-else>Spoof Call</OTitle>
    <CSocialLogin @login="onSocialLogin" v-if="!isLoggedIn"></CSocialLogin>
    <CFormFreeCall @success="onSuccess"></CFormFreeCall>
    <CAdNewsletter/>
    <o-j-l-links></o-j-l-links>
    <CShareThisInline/>
    <CFAQ v-if="$isMobile()"></CFAQ>
    <CFAQDesktop v-else></CFAQDesktop>
    <CAdNewsletter/>
  </div>
</template>

<script>
import CFormFreeCall from '../forms/CFormFreeCall'
import OJLLinks from '../objects/OJLLinks'
import CFAQ from '../components/CFAQ'
import CFAQDesktop from '../components/CFAQDesktop'
import CShareThisInline from '../components/CShareThisInline'
import OTitle from '@/objects/OTitle.vue'
import CAdNewsletter from '@/components/CAdNewsletter.vue'
import CSocialLogin from '@/components/CSocialLogin.vue'
import { mapMutations } from 'vuex'

const getApi = () => import('@/services/api')

export default {
  name: 'PageFreeCall',
  components: { CSocialLogin, CAdNewsletter, OTitle, CFAQ, CFAQDesktop, OJLLinks, CFormFreeCall, CShareThisInline },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker. Free Spoof Call To Try Now.',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call to change your CALLER ID when calling. Add voice changer for ultimate privacy. ' +
            'Try our free spoof call on this page to protect your personal information when on the phone.'
      },
      {
        name: 'keywords',
        content: 'second number,caller id, private calling'
      }
    ]
  },
  methods: {
    onSuccess ({ message }) {
      this.$router.push({ name: 'register', state: { callout: message } })
    },
    ...mapMutations(['setAuth', 'clearPhones']),
    afterLogin () {
      this.clearPhones()
    },
    submit () {
      this.$callApi(api => api.login(this.form))
    },
    onSocialLogin ({ social, credential }) {
      if (social === 'google') {
        this.onGoogleLogin(credential)
      } else if (social === 'apple') {
        this.onAppleLogin(credential)
      }
    },
    async onGoogleLogin (token) {
      const api = await getApi()
      const result = await api.googleLogin({ token })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    },
    async onAppleLogin (code) {
      const api = await getApi()
      const result = await api.appleLogin({ code })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    flex-grow: 1;
  }
}

.c_follow {
  margin-bottom: 1rem;
}
</style>
